@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';
@import '~style/abstracts/functions';

.searchPage {
  height: calc(100vh - 9.5rem);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include spacing-children($amount: 1.5rem);
}

.title {
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  color: $black-ter;
  font-weight: 800;
  font-size: convertRem(5rem);
  padding: 0 convertRem(2rem);

  border-bottom: 10px solid $primary;

  @include tablet {
    font-size: convertRem(4rem);
  }
}

@include tablet {
  .searchPage {
    padding-top: 1rem;
  }
}
