@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 9.5rem);
}

@include tablet {
  .content {
    flex-direction: column;
  }
}

