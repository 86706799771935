@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';

.title {
  color: $primary;
}

.box {
  @include desktop {
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }
}

.buttons {
  display: flex;
  @media screen and (min-width: $tablet) {
    @include spacing-children(left, 10px);
  }
  
  @include tablet {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    @include spacing-children(top, 10px);
  }
}
