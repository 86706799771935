@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';
@import '~style/abstracts/functions';

.columns {
  @include desktop {
    @include spacing-children(left, 50px);
  }

  .description {
    @include tablet {
      margin-top: convertRem(12rem);
    }
  }
}

.imageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid $grey-lightest;
  border-radius: $border-default-radius;
  padding: convertRem(1.5rem);

  @include laptop {
    padding-bottom: convertRem(2rem);
  }

  @include tablet {
    padding-bottom: convertRem(4rem);
  }
}

.image {
  width: 100%;
  height: auto;
}

.buttonWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  
  @include laptop {
    right: auto;
    left: 50%;
    transform: translate(-50%, 80%);
  }

  .requestButton {
    position: relative;
    outline: none;
    width: convertRem(12rem);
    height: convertRem(12rem);
    border: none;
    border-radius: 50%;
    background: $green;
    color: $white;
    cursor: pointer;
    transition: all .3s;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);;

    font-size: convertRem(1.5rem);

    &:hover,
    &:focus {
      transform: scale(1.05) rotate(360deg);
    }

    &:active {
      transform: scale(1) rotate(360deg);
    }

    &::after {
      content: "";
      width: convertRem(11rem);
      height: convertRem(11rem);
      border: 3px solid lighten($green, 20%);
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(convertRem(.5rem), convertRem(.5rem));
      border-radius: 50%;
    }
  }
}

.relatedProducts {
  margin-top: convertRem(8rem);
  
  @include laptop {
    margin-top: convertRem(12rem);
  }

  @include tablet {
    margin-top: convertRem(8rem);
  }
}