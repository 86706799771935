@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';

.calendarWrapper {
  background: #fff;
  padding: 4rem 4rem;
  color: $black-ter;

  border-radius: $border-default-radius;
  box-shadow: 0px 2px 3px rgba(0,0,0,0.2);

  @include tablet {
    padding: 4rem 2rem;
  }

  .title {
    margin-bottom: 2rem;
    display: inline-block;
    
    border-bottom: 3px solid $primary;
    padding: 0 .5rem;

    font-size: 2rem;
    font-weight: 700;
  }
}