@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';

.results {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .filters {
    flex: 1;
    padding-right: 2rem;
  }

  .products {
    flex: 4;
  }
}

@include tablet {
  .results {
    display: block;
  
    .filters {
      padding-right: unset;
    }

    .products {
      margin-top: 1rem;
    }
  }
}
