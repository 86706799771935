@import '~style/abstracts/variables';

.scrollable {
  max-height: 80vh;
  overflow-y: scroll;
}

.selected,
.selected:hover {
  color: $primary;
  background: $grey-lightest;
}

.disabled {
  background: $grey-lighter;
  color: $grey;
}

.disabled:hover {
  background: $grey-light;
}

.selected.disabled,
.selected.disabled:hover {
  color: $primary;
}
