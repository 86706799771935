@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';

.title {
  color: $primary;
}

.boxSize {
  margin: 0 auto;
  width: 40rem;
}

@include tablet {
  .boxSize {
    width: 90%;
  }
}