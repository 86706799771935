@import "~style/abstracts/variables";
@import "~style/abstracts/mixins";

.container {
  display: flex;
  overflow-x: auto;
  @include spacing-children(left, 1rem);

  .supplierProduct {
    border: 1px solid $grey-lightest;
    border-radius: $border-default-radius;
    padding: 0 10px;

    .image {
      max-width: 100px;
      object-fit: contain;
    }
  }
}
