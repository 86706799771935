@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';
@import '~style/abstracts/functions';

.searchContainer {
  &.multiLine {
    width: 30rem;
  }

  &.inline {
    margin-top: 1rem;
  }

  p {
    &.multiLine {
      margin-top: 1rem;
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
}

.searchBar {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  
  input {
    width: 100%;
    border: none;
    outline: none;
    transition: all .3s;
    font-family: inherit;

    padding: convertRem(1rem) convertRem(2rem);
    font-size: inherit;
    color: $black-ter;

    transform: translateX(convertRem(2.5rem));

    border: $border-width solid $grey-light;
    border-radius: $border-default-radius;

    &:focus {
      border-color: $primary;
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: convertRem(0.5rem) convertRem(1.5rem);
    cursor: pointer;
    transition: all .3s;
    font-size: convertRem(1.6rem);
    outline: none;

    color: $grey-light;
    z-index: 2;
    transform: translateX(convertRem(-2.5rem));
  }

  input:focus + button {
    color: $primary;
  }
}

@include tablet {
  .searchContainer {
    &.multiLine {
      width: 100%;
    }
  }
}
