.img {
  background-color: white;
  border-radius: 48px;
}

.cls1,
.cls3,
.cls4,
.cls6,
.cls7 {
  fill: none;
  stroke: #666;
  stroke-miterlimit: 10;
}

.cls1 {
  stroke-width: 11.91px;
}
.cls2 {
  fill: #666;
}

.cls3 {
  stroke-width: 3.04px;
}

.cls4 {
  stroke-width: 2.84px;
}

.cls5 {
  fill: #c1272d;
}

.cls6 {
  stroke-width: 3.88px;
}

.cls7 {
  stroke-width: 3.28px;
}