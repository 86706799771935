@import '../../../node_modules/bulma/sass/utilities/initial-variables.sass';

$primary: #02C4A7;
$dark: #343a40;

$border-width: 2px;
$border-default-radius: 3px;

$mobile: 425px;
$laptop: 1024px;
