@import '~style/abstracts/variables';

.column {
  height: 80vh;
  display: flex;
  flex-basis: 48%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &:hover {
    background-color: lightgrey;
    cursor: pointer;
  }
}

.title {
  color: $primary;
}
