@import '../abstracts/mixins';

.has-bg-grey-light {
  background: $grey-lightest;
}

.full-height-with-navbar {
  min-height: calc(100vh - 3.25rem);
}

.flex-full-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-full-center-mobile {
  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.is-small {
  padding: 0.2rem 0.4rem !important;
  font-size: 1rem !important;
}

.u-mr-a {
  margin-right: auto;
}

.u-ml-a {
  margin-left: auto;
}
