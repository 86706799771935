@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';

.form {
  background: #fff;
  padding: 4rem 5rem;
  color: $black-ter;

  border-radius: $border-default-radius;
  box-shadow: 0px 2px 3px rgba(0,0,0,0.2);

  @include spacing-children($amount: 2rem);
  
  @include tablet {
    padding: 4rem 2rem;
  }
}

.title {
  margin-bottom: 1rem;
  display: inline-block;
  
  border-bottom: 3px solid $primary;
  padding: 0 .5rem;

  font-size: 2rem;
  font-weight: 700;
}

.formControls {
  --gutter: 1rem;

  display: flex;
  @include spacing-children(left, var(--gutter));

  & > *{
    @include tablet {
      margin-left: 0;
      margin-top: 1rem;
    }
  }

  @for $i from 2 through 12 {
    &.isDividedBy#{$i} {
      & > * {
          flex-basis: calc(calc(100% / #{$i}) - calc((#{$i} - 1) * var(--gutter)));
      }
    }
  }

  @include tablet{
    display: block;
  }

  &.right {
    justify-content: flex-end;
  }
}