@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';

.wrapper {
  // TODO
}

.label {
  color: #363636;
  font-size: 1rem;
  font-weight: 700;
}

.mapWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  margin: 1.5rem 0;
}

.belgiumMap {
  width: 80%;
  height: 80%;

  @include tablet {
    width: 100%;
    height: 100%;
  }

  & > g {
    fill: $dark;
    transition: fill .4s;
    cursor: pointer;

    &:hover {
      fill: lighten($dark, 10%);
    }

    & > path {
      stroke: #eee;
      stroke-width: 1px;
      overflow: visible;
      
      &.selected {
        fill: $primary;
      }
    }
    
    & > text {
      fill: #eee;
      user-select: none;
    }
  }

}