// Can be included in a parent block to space
@import "~style/abstracts/variables";

// direct children by the amount given in parameter
@mixin spacing-children($direction: top, $amount) {
  & > * + * {
    margin-#{$direction}: $amount;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: $laptop) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}
