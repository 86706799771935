.loader {
  position: fixed;
  z-index: 999;
  height: 150px;
  width: 150px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}