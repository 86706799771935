@import '~style/abstracts/variables';

.container {
  min-height: calc(100vh - 3.5rem);
}

.box {
  background: #fff;
  padding: 1rem 2rem;
  border-radius: $border-default-radius;
  box-shadow: 0px 2px 3px rgba(0,0,0,0.2);

  & + & {
    margin-top: 1.5rem;
  }
}