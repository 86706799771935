@import '~style/abstracts/variables';

.filtersButton {
  display: none;
}

@media (max-width: $tablet) {
  .filtersButton {
    display: block;
  }

  .filters {
    display: none;

    &.active {
      display: block;
    }
  }
}
