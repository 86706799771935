@import '~style/abstracts/variables';

.title {
  color: $primary;
}

.button {
  margin-top: 20px;
} 

.center {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: 800px;
} 