@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';

.preview {
  height: 200px;
  max-height: 200px;
  max-width: 100%;
  border: 1px solid lightgrey;

  @include tablet {
    height: auto;
    max-height: 100%;
  }
}

.label {
  text-align: left;
}
