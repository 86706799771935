@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';

.NotificationContainer {
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 80ch;
  z-index: 100;

  @include tablet {
    max-width: 90%;
  }
}