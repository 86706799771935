@import "~style/abstracts/variables";

.cardLink {
  margin: 1.5%;

  .card {
    max-height: 600px;
    max-width: 300px;
    width: 300px;
    height: 360px;
    min-width: 200px;
    border-radius: $border-default-radius;
    cursor: pointer;

    .image {
      object-fit: contain;
      padding: 10px;
    }
  }
}
