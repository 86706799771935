@import "~style/abstracts/variables";
@import '~style/abstracts/mixins';

.customSection {
  @include tablet{
    padding: 0;
  }

  &.hasAlwaysPadding {
    @include tablet {
      padding: 1rem;
    }
  }
}