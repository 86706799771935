@import '~style/abstracts/variables';
@import '~style/abstracts/mixins';
@import '~style/abstracts/functions';

.pageNotFound {
  height: calc(100vh - 9.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: 'Montserrat', sans-serif;
  color: $black-ter;
  font-weight: 800;
  font-size: convertRem(5rem);
  padding: 0 convertRem(2rem);
  margin-bottom: 0.5em;

  border-bottom: 10px solid $primary;
}

.description {
  font-family: 'Arial', sans-serif;
  color: $black-ter;
  font-size: convertRem(2em);
  padding: 0 convertRem(2rem);
}