@import '~style/abstracts/variables';
@import '~style/abstracts/functions';

.inputControl {
  width: 100%;
  padding: convertRem(1.2rem) convertRem(2rem);
  font-size: inherit;
  color: $black-ter;
  
  outline: none;

  border: $border-width solid $grey-light;
  border-radius: $border-default-radius;

  transition: border-color .5s;

  &.invalid {
    border-color: $orange;
  }

  &.disabled {
    pointer-events: none;
    background: lighten($grey-light, 15%);
  }

  &:focus {
    border-color: $primary;
  }
}

.formControl {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > label {
    margin-bottom: 1rem;
    padding-left: 0.2rem;
  }

  &.stretch {
    flex-grow: 0;
  }

  &.isEnd {
    flex-grow: 0;
    margin-right: auto;
  }
}