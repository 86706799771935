@charset "utf-8";

// Custom variables
@import 'abstracts/variables';

// Vendors
@import '../../node_modules/bulma/bulma.sass';
@import '../../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
@import '../../node_modules/react-big-calendar/lib/sass/styles';

// Partials
@import 'abstracts/mixins';
@import 'abstracts/functions';
@import 'base/base';
@import 'base/utilities';
@import 'base/typography';

$box-link-hover-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0 0 1px transparent;
$box-link-active-shadow: inset 0 1px 2px rgba($scheme-invert, 0.2), 0 0 0 1px transparent;

/*
  Override 'z-index: 1' property of react-datepicker popper
  Reason: conflicting z-index: 4 property in react-big-calendar
  (Month numbers were overlapping the popper)
  '!important' keyword is mandatory here to override the specified property
  in the given css of the library, it is used here to ignore specificity rules
*/
.react-datepicker-popper {
  z-index: 10 !important;
}
